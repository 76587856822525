<template>
  <!-- Vendor Not Found -->
  <b-container fluid>
    <b-row no-gutters style="min-height:75vh;">
      <b-col class="text-center my-auto">
        <b-jumbotron class="text-center pl-0 pr-0">
        <template v-slot:header><h1 class="display-4 font-weight-bold mr-1">Restaurant Not Found</h1></template>
        <template v-slot:lead>
          <p class="lead mx-auto">If you believe you are seeing this in error, please contact support below.</p>
        </template>
        <a class="btn font-weight-bold justify-content-center w-auto ml-2" href="mailto:support@tblside.com">Contact</a>
        </b-jumbotron>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'InvalidVendor',
  created: function(){
    if(this.$store.state.vendor.uuid){
      this.$router.push({'name':'Home'})
    }
  }
}
</script>

<style scoped>
.jumbotron
{
  background-color:white;
}
.display-4
{
  font-weight:bolder;
}
/* Mobile Styling */
@media only screen and (max-width: 600px) {
  .display-4
  {
    font-size:2.75rem;
  }
}

</style>
